.dropdown-container {
    position: relative;
    width: 44%;
}

.custom-select {
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 0.2px solid #ccc;
    background-color: #f4fbfc;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-right: 30px;
    font-size: 14px;
    font-weight: 600; /* Space for the custom dropdown icon */
}
.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  
.custom-select:focus {
    outline: none;
    border-color:'none';
    background-color: #e7f3ff;
}

.custom-select option {
    padding: 10px;
    font-size: 13px;
}
.form-control:focus {
    outline: none; /* removes the default focus outline */
    box-shadow: none; /* removes any box-shadow that might be added on focus */
  }
  
/* Custom dropdown arrow */
.dropdown-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #666;
    pointer-events: none; /* Prevent the icon from being clickable */
}

/* Optional: Style the dropdown icon on hover or focus */
.custom-select:focus + .dropdown-icon {
    color: #5c9ded;
}

/* Base styles for the form controls */
.feedback-control-item {
    margin-bottom: 20px;
  }
  
  .feedback-control-item label {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 5px;
    color: #7439ec;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Slightly darker color for text */
  }
  
  .feedback-control-item input,
  .feedback-control-item textarea,
  .feedback-control-item select,
  .feedback-control-item file {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #DEE2E6;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .feedback-control-item input:focus,
  .feedback-control-item textarea:focus,
  .feedback-control-item select:focus {
    border-color: #7439ec; /* Focus color matching the theme */
  }
  
  .feedback-control-item textarea {
    height: 100px; /* Adjust height for textareas */
  }
  
  .feedback-control-item select {
    padding-left: 10px; /* Adjust padding to align text with select icon */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .feedback-control-item select:focus {
    border-color: #7439ec;
  }
  
  .feedback-control-item .dropdown-container {
    position: relative;
  }
  
  .feedback-control-item .dropdown-container .custom-select {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #DEE2E6;
    border-radius: 5px;
    outline: none;
  }
  
  .feedback-control-item .dropdown-container .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 1.2rem;
    color: #7439ec;
  }
  .feedback-control-item input {
    width: 600px;
  }
  .feedback-control-item textarea{
    width: 600px;
  }
  .feedback-control-item select{
    width: 600px;
  }
 
  .feedback-control-item input[type="file"] {
    padding: 5px;
    background-color: #f8f9fa;
    border: 1px solid #DEE2E6;
    border-radius: 5px;
    width: 600px;
  }
  
  .feedback-control-item .control-description {
    font-size: 0.675rem;
    color: #7439ec;
    margin-top: 5px;
    width: 600px;
  }

  
  /* Submit Button container */
.SubmitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
  }
  .feedback_control_body{
    margin-top:2%;
  }
  /* Submit Button styling */
  .submit-btn {
    padding: 12px 30px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff; /* Blue background */
    border: none;
    border-radius: 30px; /* Rounded corners */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    width: 600px;
    margin-top:2%;
  }
  
  /* Button hover effect */
  .submit-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
  }
  
  /* Button focus effect */
  .submit-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5); /* Blue glow on focus */
  }
  
  /* Optional: Add a disabled state */
  .submit-btn:disabled {
    background-color: #c0c0c0; /* Gray background for disabled */
    cursor: not-allowed;
  }
  